<template>
  <v-app id="inspire">
    <overall-header/>


    <v-main class=" lighten-3">
      <v-container>
        <v-row
            align="start"
            justify="space-around"
            style="padding: 5vh"
        >
          <h1 v-if="$route.params.id === undefined">Новый объект</h1>
          <h1 v-else-if="$route.params.id !== undefined">Редактировать объект</h1>
        </v-row>

        <v-row
            justify="space-between"
        >
          <v-col
              cols="12"
              md="12"
          >
            <v-form ref="form">
              <v-text-field
                  v-model="project_title"
                  label="Название объекта"
                  required
                  @input="$v.project_title.$touch()"
                  @blur="$v.project_title.$touch()"
              ></v-text-field>

              <v-text-field
                  v-model="project_address"
                  label="Адрес объекта"
                  required
                  @input="$v.project_address.$touch()"
                  @blur="$v.project_address.$touch()"
              ></v-text-field>

              <v-text-field
                  v-model="name_developer"
                  label="Застройщик"
                  required
                  @input="$v.name_developer.$touch()"
                  @blur="$v.name_developer.$touch()"
              ></v-text-field>

              <v-text-field
                  v-model="name_technical_customer"
                  label="Технический заказчик"
                  required
                  @input="$v.name_technical_customer.$touch()"
                  @blur="$v.name_technical_customer.$touch()"
              ></v-text-field>

              <v-text-field
                  v-model="name_project_organization"
                  label="Проектные организации"
                  required
                  @input="$v.name_project_organization.$touch()"
                  @blur="$v.name_project_organization.$touch()"
              ></v-text-field>

<!--              <v-text-field-->
<!--                  v-model="project_code"-->
<!--                  label="Разделы проекта"-->
<!--                  required-->
<!--                  @input="$v.project_code.$touch()"-->
<!--                  @blur="$v.project_code.$touch()"-->
<!--              ></v-text-field>-->

              <!--              <v-checkbox-->
              <!--                  v-model="project_archive"-->
              <!--                  name="archive"-->
              <!--                  label="Архив"-->
              <!--                  @input="$v.project_archive.$touch()"-->
              <!--                  @blur="$v.project_archive.$touch()"-->
              <!--              ></v-checkbox>-->

              <!--              <v-select-->
              <!--                  :items="status_items"-->
              <!--                  item-text="title"-->
              <!--                  item-value="id"-->
              <!--                  v-model="project_status"-->
              <!--                  label="Статус"-->
              <!--                  dense-->
              <!--                  required-->
              <!--                  @input="$v.project_status.$touch()"-->
              <!--                  @blur="$v.project_status.$touch()"-->
              <!--              ></v-select>-->


              <!--              <v-datetime-picker-->
              <!--                  v-model="project_deadline"-->
              <!--                  :time-picker-props="timePickerProps"-->
              <!--              >-->
              <!--                <template slot="dateIcon">-->
              <!--                  <v-icon small>mdi-calendar</v-icon>-->
              <!--                </template>-->
              <!--                <template slot="timeIcon">-->
              <!--                  <v-icon small>mdi-alarm</v-icon>-->
              <!--                </template>-->
              <!--              </v-datetime-picker>-->

              <!--              <v-datetime-picker-->
              <!--                  v-model="project_answer_deadline"-->
              <!--                  :time-picker-props="timePickerProps"-->
              <!--              >-->
              <!--                <template slot="dateIcon">-->
              <!--                  <v-icon small>mdi-calendar</v-icon>-->
              <!--                </template>-->
              <!--                <template slot="timeIcon">-->
              <!--                  <v-icon small>mdi-alarm</v-icon>-->
              <!--                </template>-->
              <!--              </v-datetime-picker>-->
              <v-row justify="space-between">
                <v-col
                    cols="12"
                    md="12"
                >
                  <v-text-field
                      v-model="search"
                      label="Поиск по секциям"
                      flat
                      hide-details
                      clearable
                      clear-icon="mdi-close-circle-outline"
                  ></v-text-field>
                  <v-treeview
                      selectable
                      open-all
                      :items="section_items"
                      :search="search"
                      :filter="filter"
                      v-model="project_sections"
                      item-text="title"
                  >

                    <template v-slot:append="{ item, selected }">
                      <v-select
                          :items="workers"
                          v-on:change="selectDefaultWorkers(item, selected, $event)"
                          :value="getDefaultWorkers(item)"
                          :menu-props="{ maxHeight: '400' }"
                          label="Выбрать дефолтных исполнителей"
                          multiple
                          persistent-hint
                          item-text="title"
                          item-value="id"
                      ></v-select>
                    </template>

                  </v-treeview>
                </v-col>
              </v-row>

            </v-form>
          </v-col>

          <!--          <v-col-->
          <!--              cols="12"-->
          <!--              md="6"-->
          <!--          >-->
          <!--            <tiptap-vuetify-->
          <!--                v-model="project_description"-->
          <!--                :extensions="editor_extensions"-->
          <!--                placeholder="Описание проекта"-->
          <!--                @input="$v.project_description.$touch()"-->
          <!--                @blur="$v.project_description.$touch()"-->
          <!--            />-->
          <!--          </v-col>-->
        </v-row>
        <v-row justify="space-between">
          <v-col
              cols="6"
              md="6"
          >
<!--            <v-multiselect-listbox :options="workers"-->
<!--                                   :reduce-display-property="(option) => option.label"-->
<!--                                   :reduce-value-property="(option) => option.code"-->
<!--                                   v-model="default_workers"-->
<!--            ></v-multiselect-listbox>-->


            <!--            <v-text-field-->
            <!--                v-model="search"-->
            <!--                label="Поиск по секциям"-->
            <!--                flat-->
            <!--                hide-details-->
            <!--                clearable-->
            <!--                clear-icon="mdi-close-circle-outline"-->
            <!--            ></v-text-field>-->
            <!--            <v-treeview-->
            <!--                selectable-->
            <!--                open-all-->
            <!--                :items="section_items"-->
            <!--                :search="search"-->
            <!--                :filter="filter"-->
            <!--                v-model="project_sections"-->

            <!--                item-text="title"-->
            <!--            ></v-treeview>-->
          </v-col>
          <v-col
              cols="6"
              md="6"
          >

            <v-dialog
                v-model="new_worker_dialog"
                persistent
                max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    tile
                    color="primary"
                    @click="createNewWorker"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Создать нового исполнителя
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Новый исполнитель</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            label="ФИО"
                            required
                            v-model="new_worker_fio"
                        ></v-text-field>

                        <v-text-field
                            label="Должность"
                            hint="Введите должность исполнителя"
                            v-model="new_worker_position"
                        ></v-text-field>

                        <v-file-input
                            chips
                            show-size
                            counter
                            label="Прикрепите факсимиле"
                            v-model="new_worker_sign"
                        ></v-file-input>
                        <v-img :src="url"/>
                      </v-col>


                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="new_worker_dialog = false"
                  >
                    Закрыть
                  </v-btn>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="handleWorkerCreation"
                  >
                    Сохранить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </v-col>

        </v-row>

        <v-btn
            x-large
            color="success"
            dark
            @click="createProject"
            v-if="$route.params.id === undefined"
        >
          Создать проект
        </v-btn>
        <v-btn
            x-large
            color="primary"
            dark
            @click="editProject"
            v-if="$route.params.id !== undefined"
        >
          Обновить
        </v-btn>
      </v-container>
    </v-main>


  </v-app>
</template>

<script>
import OverallHeader from "@/components/OverallHeader";
import {
  // TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';
import {imageToBase64} from "../../helpers/imageToBase64";

export default {
  name: "CreateProject",
  components: {OverallHeader},
  mixins: [validationMixin],

  validations: {
    project_title: {required},
    project_code: {required},
    project_address: {required},
    name_developer: {required},
    name_technical_customer: {required},
    name_project_organization: {required},
  },

  data: () => ({
    project_deadline: new Date(Date.now()),
    project_deadline_menu: false,

    project_answer_deadline: new Date(Date.now()),
    project_answer_deadline_menu: false,
    project_description: "",
    project_status: '',
    project_title: "",
    project_code: "",
    name_developer: "",
    name_technical_customer: "",
    name_project_organization: "",
    project_address: "",
    default_workers: {},
    project_sections: [],
    current_project_files: [],
    project_files: [],
    uploaded_files: [],
    editor_extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    search: null,
    new_worker_dialog: false,
    new_worker_sign: null,
    new_worker_sign_base64: null,
    new_worker_position: "",
    new_worker_fio: "",
  }),

  computed: {
    ...mapGetters('iul_workers', {
      workers: 'list'
    }),
    ...mapGetters('iul_sectiontypes', {
      section_items: 'list'
    }),
    filter() {
      return (item, search, textKey) => item[textKey].indexOf(search) > -1
    },
    url() {
      if (!this.new_worker_sign) return;
      return URL.createObjectURL(this.new_worker_sign);
    }
  },

  methods: {
    ...mapActions('iul_projects', {
      fetchProject: 'fetchSingle',
      updateProject: 'replace',
      createIulProject: 'create',
    }),
    ...mapActions('iul_workers', {
      fetchWorkers: 'fetchList',
      createWorker: 'create',
    }),
    ...mapActions('iul_sectiontypes', {
      fetchSectionTypes: 'fetchList'
    }),
    buildFormData: function () {
      let sections = [];
      let workers = {};

      this.project_sections.map((s) => {
        sections.push({pk: s});
        if(this.default_workers[s]) {
          workers[s] = this.default_workers[s];
        } else {
          workers[s] = [];
        }
      });

      let data = {
        "title": this.project_title,
        "address": this.project_address,
        "name_developer": this.name_developer,
        "name_technical_customer": this.name_technical_customer,
        "name_project_organization": this.name_project_organization,
        "project_code": this.project_code,
        "default_workers_and_sections": workers,
      }
      return data
    },

    createProject: function () {
      this.$v.$touch()
      let that = this;

      let data = that.buildFormData();
      this.createIulProject({
        data: data
      }).then((r) => {
        that.$router.push('/edit_iul_project/' + r.data.id)
      });
    },

    editProject: function () {
      this.$v.$touch()
      let that = this;
      this.updateProject({id: this.$route.params.id, data: this.buildFormData()}).then(function () {
        that.loadDataForProject();
      });
    },

    loadDataForProject: function () {
      if (this.$route.params.id) {
        let that = this;
        this.fetchProject({id: this.$route.params.id}).then(function (response) {
          that.project_title = response.data.title;
          that.project_address = response.data.address;
          that.name_developer = response.data.name_developer;
          that.name_technical_customer = response.data.name_technical_customer;
          that.name_project_organization = response.data.name_project_organization;
          that.project_sections = Object.keys(response.data.default_workers_and_sections);
          that.default_workers = response.data.default_workers_and_sections;
        });
      }
    },

    createNewWorker() {
      this.new_worker_dialog = true;
    },

    selectDefaultWorkers(item, selected, value) {
      this.default_workers[item.id] = value;
      console.log("selectDefaultWorkers", this.default_workers);
    },

    getDefaultWorkers(item) {
      return this.default_workers[item.id];
    },

    handleWorkerCreation() {
      if (!this.new_worker_sign) return;
      var that = this;
      imageToBase64(this.new_worker_sign).then((base64) => {
        this.createWorker(
            {
              data: {
                "fio": this.new_worker_fio,
                "sign": base64,
                "position": this.new_worker_position,
              },

            },
        ).then(() => {
          that.fetchWorkers();
          that.new_worker_fio = "";
          that.new_worker_position = "";
          that.new_worker_sign = null;
          that.new_worker_sign_base64 = null;
          that.new_worker_dialog = false;
        });
      });
    },
  },

  created() {
    this.loadDataForProject();
    this.fetchSectionTypes();
    this.fetchWorkers();
  },

  watch: {
    project_title: 'buildFormData',
    project_address: 'buildFormData',
  }
}
</script>

<style scoped>

</style>